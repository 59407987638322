// dispositionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const requisitionSlice = createSlice({
  name: "disposition",
  initialState: {
    successMoved: false,
    successChangedPriority:false,
    ProfileList: [],
    retaTreeData: [],
    data: null,
    error: null,
    loading: false,
    isSearch: false,
    selectedprofiles: [{}],
    requisitionDetailFormData:null,
    redonamesgenfeature:{
      "isEmployeeSourcer": 0,
      "isEmployeeRecruiter": 0,
      "isNameGenRedoFeatureEnabled": 0
    },
    requisitioneditdata:[{}],
    requisitionModifyMode:"edit",
    retadashboardredonamesgenemptype:0,
    currentmodalrequisitionstate:0,
    refreshLeftSectionTreeWithPositiveNumber:0,
    refreshRequisitionModelWithPositiveNumber:0,
    customRetaData:"",
    autocompleteresultsmaintoolbar:[]
  },
  reducers: {
    setrequisitionDetailFormData: (state, action) => {
      state.requisitionDetailFormData = action.payload;
      state.error = null;
      state.loadingInfo = false;
    },
    setMovingProfile: (state) => {
      state.movingProfile = true;
      state.successMoved = false;
    },
    setChangedProfilePriority: (state) => {
      state.changingProfilePriority = true;
      state.successChangedPriority = false;
    },
    setAssociatetoRequisition: (state) => {
      state.changingAssociatetoRequisition = true;
      state.successAssociatetoRequisition = false;
    },
    setRedonamesgenfeature: (state,action) => {
      state.redonamesgenfeature = action.payload;
    },
    setSelectedProfile: (state, action) => {
      state.selectedprofiles = action.payload;
    },
    setMovedProfile: (state) => {
      state.movingProfile = false;
      state.successMoved = true;
    },
    setCheck: (state) => {},
    setSelect1InfoData: (state, action) => {
      console.log(action.payload);
      state.rejectSelect1 = action.payload;
      state.error = false;
      state.loading = false;
    },
    setRequisitionInfoData: (state, action) => {
      state.requistionInfo = action.payload;
      state.error = false;
      state.loadingInfo = false;
    },
    setRequisitionEditInfoData: (state, action) => {
      state.requisitioneditdata = action.payload;
      state.error = false;
      state.loadingInfo = false;
    },

    retaCustomTreeRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    retaRequisitionInfoRequest: (state) => {
      state.error = null;
      state.loadingInfo = true;
    },
    retaRequisitionEditInfoRequest: (state) => {
      state.error = null;
      state.loadingEditInfo = true;
    },
    retaTreeRequest: (state, action) => {
      if (action.payload.searchedProfile.length)
        state.isSearch = action.payload.searchedProfile;
      else state.isSearch = false;
      state.loading = true;
      state.error = null;
    },
    setRetaTreeData: (state, action) => {
      console.log(action?.payload, "data===");
      state.averageFillTime = action.payload.AverageFillTime;
      state.retaTreeData = action.payload.Data;
      state.loading = false;
    },
    setAssociateData: (state, action) => {
      state.associateSelectData = action.payload;
      state.error = false;
    },
    retaTreeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUserListData: (state, action) => {
      state.ProfileList = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selected = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loadingInfo = false;
      state.loading = false;
      state.successMoved = false;
    },
    setRequisitionModifyMode:(state,action)=>{
      state.requisitionModifyMode=action.payload;
    },
    setCurrentRequisitionModelStage:(state,action)=>{
      state.currentmodalrequisitionstate=action.payload;
    },
    setRefreshLeftSectionTreeWithPositiveNumber(state,action){
      state.refreshLeftSectionTreeWithPositiveNumber=action.payload
    },
    setRefreshRequisitionModelWithPositiveNumber(state,action){
      state.refreshRequisitionModelWithPositiveNumber=action.payload
    },
    setCustomRetaData(state,action){
      state.customRetaData=action.payload;
    },
    setAutocompleteresultsmaintoolbar:(state,action)=>{
      state.autocompleteresultsmaintoolbar=action.payload;
    }
  },
});

export const {
  setData,
  setError,
  setSelectedSection,
  setUserListData,
  setRetaTreeData,
  setStockInfoData,
  setSelect1InfoData,
  retaTreeFailure,
  retaTreeRequest,
  setAssociateData,
  retaCustomTreeRequest,
  retaCompanyInfoTreeRequest,
  setCompanyInfoData,
  setMovingProfile,
  setMovedProfile,
  retaRequisitionInfoRequest,
  retaRequisitionEditInfoRequest,
  setRequisitionInfoData,
  setSelectedProfile,
  setrequisitionDetailFormData,
  setChangedProfilePriority,
  setAssociatetoRequisition,
  setRedonamesgenfeature,
  setRequisitionEditInfoData,
  setRequisitionModifyMode,
  setCurrentRequisitionModelStage,
  setRefreshLeftSectionTreeWithPositiveNumber,
  setRefreshRequisitionModelWithPositiveNumber,
    setCustomRetaData,
  setAutocompleteresultsmaintoolbar
} = requisitionSlice.actions;
export default requisitionSlice.reducer;
