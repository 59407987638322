// slices/authSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { deleteCookies } from "../utils/helpers/deleteCookies";


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    loading: false,
    eid:0,
    autologinsuccess:false,
    redirectFrom:"https://my.ipex.services/"
  },
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
    
      state.loading = false;
      state.user = action.payload;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
        localStorage.removeItem('token')
        deleteCookies()
    
        state.user = null;
        state.error = null;
      },
    setEID:(state,action)=>{
      state.eid=action.payload
    },
    setAutoLoginSuccess:(state,action)=>{
      state.autologinsuccess=action.payload
    },
    setRedirectFrom:(state,action)=>{
      state.redirectFrom=action.payload;
    }
  },
});

export const { loginRequest, loginSuccess, loginFailure,logout,setEID,setAutoLoginSuccess ,setRedirectFrom} = authSlice.actions;

export default authSlice.reducer;
