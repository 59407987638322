// dispositionSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  retaCustomTreeRequest,
  retaRequisitionEditInfoRequest,
  retaRequisitionInfoRequest,
  retaTreeRequest,
  setAssociateData,
  setAssociatetoRequisition, setAutocompleteresultsmaintoolbar,
  setChangedProfilePriority,
  setCurrentRequisitionModelStage, setCustomRetaData,
  setData,
  setError,
  setMovedProfile,
  setMovingProfile,
  setRedonamesgenfeature,
  setRefreshLeftSectionTreeWithPositiveNumber,
  setrequisitionDetailFormData,
  setRequisitionEditInfoData,
  setRequisitionInfoData,
  setRetaTreeData,
  setSelect1InfoData, setSelectedSection,
} from "../slices/requisitionSlice";
import api from "../api/api";
import { toast } from "react-toastify";
import {
  retaCompanyInfoTreeRequest,
  setCompanyError,
  setCompanyInfoData,
  setStockInfoData,
} from "../slices/companySlice";
import {CommonSuccess} from "../helpers/toastMethod";

function* fetchDispositionData() {
  try {
    const response = yield call(apiDepositionDataFunction, {
      isSysAdmin: true,
      isMSM: true,
    });

    yield put(setData(response.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* fetchRetaData(action) {
  try {
    let response = {};
    const isSmall = action?.payload?.isSmall;
    if (isSmall) {
      response = yield call(apiRetaTree, action.payload);
      const data = {
        AverageFillTime: response.data?.AverageFillTime,
        Data: response?.data?.Data,
      };
      yield put(setRetaTreeData(data));
    } else {
      response = yield call(apiGetRetaTreeNEW, action.payload);
      const data = {
        AverageFillTime: response?.data?.AverageFillTime,
        Data: response?.data?.NData,
      };
      yield put(setRetaTreeData(data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}
function* fetchAssociateSelect() {
  try {
    const response = yield call(apissociateToReq);

    yield put(setAssociateData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchCompanyInfo(action) {
  try {
    const response = yield call(apiCompanyInfo, action.payload);
    yield put(setCompanyInfoData(response.data));
  } catch (error) {
    console.log("wrong");
    CommonError("Something Went Wrong");
    yield put(setCompanyError(error));
  }
}
function* fetchStockInfo(action) {
  console.log(action?.id);
  try {
    const response = yield call(apiStockInfo, action?.id);
    yield put(setStockInfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setCompanyError(error));
  }
}
function* fetchRequisitionInfo(action) {
  try {
    const response = yield call(apiRequisitionInfo, action.payload);
    yield put(setRequisitionInfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchRequisitionEditInfo(action) {
  try {
    const response = yield call(apiRequisitionEditInfo, action.payload);
    yield put(setRequisitionEditInfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchRequisitionDropDownInfo() {
  try {
    const response = yield call(apiRequisitionDropDownInfo);
    yield put(setrequisitionDetailFormData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* GetMoveProfile(action) {
  try {
    const response = yield call(apiMoveProfile, action.payload);
    console.log(response, "resposne");
    if (response?.data.result === -1) {
      CommonError("Access Denied");
      setError("Access Denied");
      return;
    }
    yield put(setMovedProfile());
    if(response?.data.result===9){
      CommonSuccess("Profiles rejected successfully.");
    }
    if(response?.data.result===5){
      //console.log(response?.data.reqID);
      yield put(setCurrentRequisitionModelStage(response?.data.reqID));
      CommonSuccess("This profile filled all available spots, the requisition has now been moved to Filled");
      //refresh tree and show requisition information
      yield put(setRefreshLeftSectionTreeWithPositiveNumber(1));
      yield put(retaRequisitionInfoRequest({ reqID: response?.data.reqID}));
      yield put(setSelectedSection("requisition"));
    }
    if(response?.data.result===10){
      CommonSuccess("This profile is already dispositioned in the selected stage. ");
    }
    if(response?.data.result!==9 && response?.data.result!==5 && response?.data.result!==10){
      CommonSuccess("Profile Dispositioned Successfully!");
    }
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
// API call function with headers

function* ChangeProfilePriorityAPI(action) {
  try {
    const response =yield call(apiChangeProfilePriority, action.payload);
    console.log(response, "resposne");
    if (response?.data === -1) {
      CommonError("Access Denied");
      setError("Access Denied");
      return;
    }
    yield put(setChangedProfilePriority());
  } catch (error) {
    //CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* AssociatetoRequisitionAPI(action) {
  try {
    const response =yield call(apiAssociatetoRequisition, action.payload);
    console.log(response, "resposne");
    if (response?.data === -1) {
      CommonError("Access Denied");
      setError("Access Denied");
      return;
    }
    yield put(setAssociatetoRequisition());
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchMoveProfileSelectData1(action) {
  try {
    const response = yield call(apiMoveProfileSelectData1, action.payload);
    // console.log(response,'resposne');
    yield put(setSelect1InfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchRedoNamesGenAvailabilityInfo(action) {
  try {
    const response = yield call(apiRedoNamesGenAvailabilityInfo,action.payload);
    // console.log(response,'resposne');
    yield put(setRedonamesgenfeature(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchCustomRetaTreeInfo(action) {
  try {
    const response = yield call(apiCustomTreeInfo,action.payload);
    // console.log(response,'resposne');
    yield put(setCustomRetaData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}

function* fetchProfileByReqId(action) {
  try {
    const response = yield call(apiGetProfilesbyByReqID, action.payload);
    // console.log(response,'resposne');
    yield put(setSelect1InfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}

function* fetchProfileSuggestions(action) {
  try {
    const response = yield call(axios.post,
        process.env.ELASTICSEARCH_API_URL,
        action.payload, // Assuming the request data is in action.payload
        {
          headers: {
            "wlwebapikey": process.env.ELASTICSEARCH_API_KEY,
            "Content-Type": "application/json; charset=utf-8"
          }
        }
    );

    // Assuming you have a setSuggestions action in your Redux store
    yield put(setAutocompleteresultsmaintoolbar(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    // Assuming you have an setError action in your Redux store
    yield put(setError(error));
  }
}

export function* fetchProfileSuggestionsSaga(action) {
  yield takeLatest(setAutocompleteresultsmaintoolbar, fetchProfileSuggestions);
}

export function* dispositionSaga() {
  yield takeLatest("disposition/fetchData", fetchDispositionData);
}
export function* RedoNamesGenFeatureSaga() {
  yield takeLatest("Requisition/RedoNamesGenFeatureData", fetchRedoNamesGenAvailabilityInfo);
}
export function* CustomRetaTreeInfoSaga() {
  yield takeLatest("Requisition/RedoNamesGenFeatureData", fetchCustomRetaTreeInfo);
}

export function* RetaTreeSaga() {
  yield takeLatest(retaTreeRequest, fetchRetaData);
}
export function* associateSelectSaga() {
  yield takeLatest("Requisition/GetRequisitions", fetchAssociateSelect);
}
export function* CompanyInfoSaga() {
  yield takeLatest(retaCompanyInfoTreeRequest, fetchCompanyInfo);
}
export function* StockInfoSaga() {
  yield takeLatest("Requisition/GetStockInformation", fetchStockInfo);
}
export function* GetMoveProfileSaga() {
  yield takeLatest(setMovingProfile, GetMoveProfile);
}
export function* ChangedProfilePrioritySaga() {
  yield takeLatest(setChangedProfilePriority, ChangeProfilePriorityAPI);
}
export function* AssociatetoRequisitionSaga() {
  yield takeLatest(setAssociatetoRequisition, AssociatetoRequisitionAPI);
}
export function* RequisitionInfoSaga() {
  yield takeLatest(retaRequisitionInfoRequest, fetchRequisitionInfo);
}
export function* RequisitionEditInfoSaga() {
  yield takeLatest(retaRequisitionEditInfoRequest, fetchRequisitionEditInfo);
}
export function* RequisitionDropDownInfoSaga() {
  yield takeLatest(setRequisitionInfoData, fetchRequisitionDropDownInfo);
}
export function* RejectSelect1Saga() {
  yield takeLatest("Requisition/GetReasonCodes", fetchMoveProfileSelectData1);
}
export function* fetchProfilesFromReq() {
  yield takeLatest("disposition/fetchData", fetchDispositionData);
}


function apiDepositionDataFunction(userData) {
  return api.post("disposition/populate", userData);
}
function apiRetaTree(body) {
  return api.post("Requisition/reta-tree", body);
}
function apissociateToReq() {
  return api.get("Requisition/GetRequisitions");
}

function apiCompanyInfo(body) {
  return api.get("Requisition/GetReqCompanydata/" + body);
}
function apiStockInfo(body) {
  console.log(body);
  return api.get("Requisition/GetStockInformation/" + body);
}
function apiMoveProfile(body) {
  return api.post("Requisition/GetMoveProfileToOtherLevel", body);
}
function apiChangeProfilePriority(body) {
  return api.post("Requisition/ChangePriority", body,{headers:{
      'Content-Type': 'application/json'
    }});
}
function apiAssociatetoRequisition(body) {
  return api.post("Requisition/AssociateProfileToReq", body,{headers:{
      'Content-Type': 'application/json'
    }});
}
function apiRequisitionEditInfo(body) {
  return api.get("Requisition/GetReqEditInformation/" + body?.reqID);
}
function apiRequisitionInfo(body) {
  return api.get("Requisition/GetReqInformation/" + body?.reqID);
}
function apiRedoNamesGenAvailabilityInfo(EmpID) {
  return api.get("Requisition/RedoNamesGenFeatureData/"+EmpID);
}
function apiCustomTreeInfo(EmpID) {
  return api.get("Requisition/GetCustomProject/"+EmpID);
}

function apiRequisitionDropDownInfo() {
  return api.get("Requisition/GetReqDropDownInformation/");
}
function apiMoveProfileSelectData1(body) {
  return api.get(`Requisition/GetReasonCodes`);
}
export function apiGetProfilesbyByReqID(body) {
  const queryParams =
    body && body.isSearch ? `&searchedProfile=${body.isSearch}` : "";
  return api.get(
    `Requisition/GetProfilesbyByReqID/?ReqID=${body?.id}${queryParams}`
  );
}
export function apiGetRetaTreeNEW(body) {
  return api.post("Requisition/reta-tree-New", body);
}

const CommonError = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
